
import Vue from "vue"
import {
  CharacterData,
  validateNewCharacter,
} from "@/data/character/characterData"
import FLButton from "@/components/base/FLButton.vue"
import ModalConfirm from "@/components/ModalConfirm.vue"

export default Vue.extend({
  name: "CharacterCreatorCard",
  components: {
    FLButton,
    ModalConfirm,
  },
  props: {
    charData: Object as () => CharacterData | null,
    titleOverride: String,
    linkOverride: String,
    clickDisabled: Boolean,
  },
  data() {
    return {
      modalActive: false,
      actionsActive: false,
      modalConfirmActivate: false,
    }
  },
  computed: {
    characterId(): string {
      if (!this.charData) return ""
      return this.charData.metadata.id
    },
    empty(): boolean {
      return !this.charData
    },
    cardLink(): string {
      if (this.linkOverride) return this.linkOverride
      if (this.empty) return `new`
      if (this.newChar) return `new/edit/${this.characterId}`
      return `active/edit/${this.characterId}`
    },
    newCharValid(): boolean {
      return !!this.charData && validateNewCharacter(this.charData)
    },
    newChar(): boolean {
      return (
        !!this.charData &&
        ["new", undefined].includes(this.charData.metadata.status)
      )
    },
  },
  methods: {
    edit(ev: Event) {
      if (ev) {
        ev.preventDefault()
      }
      this.$router.push(this.cardLink)
    },
    cardClicked() {
      this.actionsActive = !this.actionsActive
    },
    remove() {
      if (this.charData) {
        this.$emit("remove-card", this.charData.metadata.id)
      }
      this.closeModal()
    },
    confirmRemove() {
      this.modalActive = true
    },
    closeModal() {
      this.modalActive = false
    },
    activateClicked() {
      if (this.newChar && !this.newCharValid) {
        this.modalConfirmActivate = true
      } else {
        this.activate()
      }
    },
    activate() {
      this.charData && this.$emit("activate", this.charData.metadata.id)
      this.actionsActive = false
    },
    deactivate() {
      this.charData && this.$emit("free-edit", this.charData.metadata.id)
      this.actionsActive = false
    },
  },
})
