
import { Component, Vue } from "vue-property-decorator"
import Card from "@/components/Card.vue"
import CharacterCard from "@/components/CharacterCard.vue"
import Expander from "@/components/ExpandableSection.vue"
import { SaveData } from "@/localstorage/characterStorage"
import FileReader from "@/components/FileReader.vue"
import { setTimeout } from "timers"
import { SET_PAGE_TITLE } from "@/store/store-types"

function generateDateString(): string {
  return "backup-" + new Date().toISOString() + ".charlist.flcdata"
}

@Component({
  components: {
    Card,
    CharacterCard,
    Expander,
    FileReader,
  },
})
export default class CharacterListView extends Vue {
  importKey = 1

  importData: SaveData | null = null
  importName = ""

  exportBlob: string = ""
  exportFilename: string = ""

  newCharacters = this.$characterStore.charactersByStatus(["new", undefined])
  activeCharacters = this.$characterStore.activeCharacters
  inactiveCharacters = this.$characterStore.charactersByStatus("freeEdit")

  mounted() {
    this.$store.commit(SET_PAGE_TITLE, "Characters")
  }

  updateCharacters() {
    // There is probably be a better way to handle this, but it will do for now
    this.newCharacters = this.$characterStore.charactersByStatus([
      "new",
      undefined,
    ])
    this.activeCharacters = this.$characterStore.activeCharacters
    this.inactiveCharacters = this.$characterStore.charactersByStatus(
      "freeEdit"
    )
  }

  removeCard(characterId: any) {
    this.$characterStore.removeCharacter(characterId, true)
    this.updateCharacters()
  }

  activate(characterId: string) {
    this.$characterStore.activate(characterId, true)
    this.updateCharacters()
  }

  deactivate(characterId: string) {
    this.$characterStore.deactivate(characterId, true)
    this.updateCharacters()
  }
}
