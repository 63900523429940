
import Vue from "vue"
import { Component, Prop, Watch } from "vue-property-decorator"

import FLButton from "@/components/base/FLButton.vue"
import Modal from "@/components/Modal.vue"
// https://github.com/vuejs/vue-class-component/blob/master/example/src/App.vue

@Component({
  components: {
    FLButton,
    Modal,
  },
})
export class ModalConfirm extends Vue {
  @Prop({ required: true }) confirmAction!: CallableFunction
  @Prop({ default: "" }) title!: string
  @Prop({ default: "" }) body!: string
  @Prop({ default: true }) showTitle!: boolean
  @Prop({ default: false }) danger!: boolean

  get mTitle() {
    if (!this.showTitle) return ""
    if (this.title) return this.title
    return this.$t("confirm")
  }

  close() {
    this.$emit("close")
  }
}

export default ModalConfirm
